export const REQUEST_REWARD = 'REQUEST_REWARD'
export const SET_ORDER_INFO = 'SET_ORDER_INFO'
export const ORDER_ID_FAILED = 'ORDER_ID_FAILED'
export const ORDER_ID_SUCCESS = 'ORDER_ID_SUCCESS'


export const PUSH_TO = 'PUSH_TO'
export const BACK_GO = 'BACK_GO'

export const SET_STAR = 'SET_STAR'
export const SET_PERIOD = 'SET_PERIOD'

export const TOGGLE_NEWSLETTER = 'TOGGLE_NEWSLETTER'

export const SET_IMAGE_LIST = 'SET_IMAGE_LIST'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_FEEDBACK_TEXT = 'SET_FEEDBACK_TEXT'
export const SET_BENEFIT ='SET_BENEFIT'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const SET_ADDRESS_INFO = 'SET_ADDRESS_INFO'


export const SET_FREE_PRODUCTS_INFO = 'SET_FREE_PRODUCTS_INFO'