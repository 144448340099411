import * as types from './types';

import axios from 'axios';
import config from 'react-global-configuration';
import configuration from '../config/config';

import {
  notification,
} from 'antd';
import REMOTEHOST from '../remote-host';
const remoteUrl = REMOTEHOST;


function setOrderInfo(response) {

  return {
    type: types.SET_ORDER_INFO,
    data: response
  }
}

export function sendOrderId(order_id,email,username){

  return (dispatch,getState) => {
    var request_url = `${remoteUrl}/graphql?query={order(amazon_order_id:%20%22${order_id}%22)%20{seller_id%20AmazonOrderId%20redeem%20{requestDate%20how_to_help}%20items%20{ASIN%20country%20ItemPriceUSD}%20reward}}`

    return axios.get(request_url).then((response)=>{
      var result = response['data']['data']['order']
      if (OrderIdResponse(dispatch,result)){
        dispatch(push('feedback'))
        dispatch(setUserInfo(username,email))
        dispatch(send_email())
        dispatch(fetchFreeProducts(order_id))
        
      }

    }).catch((err)=>{

      notification['error']({
        message: 'Server is under maintenance.',
        description:'Sorry! Server is under maintenance. Please try later!'
      })
    })
  }
}

function fetchFreeProducts(order_id){
  return (dispatch,getState) => {
    var request_url = `${remoteUrl}api/v1/free-products/${order_id}`
    return axios.get(request_url).then((response)=>{
        dispatch(setFreeProductsInfo(response['data']))
   
    })
  }
}
export function setFreeProductsInfo(freeProductsInfo){

  return {
    type: types.SET_FREE_PRODUCTS_INFO,
    data: freeProductsInfo
  }
}

function OrderIdResponse(dispatch,data){
  if (data === null){
    notification['error']({
      message: 'Wrong order number.',
      description:'Your order number is not right. 1. Please check it again，the order id should be like this: 123-1234567-1234567. 2.Your order id can not be found，please use another order number to try again.'
    });
  }else if (data.items.length === 0){
    notification['error']({
      message: 'No Item.',
      description:'Sorry, there is no item in this order. Please change the order ID and try again.'
    });
  }else if (data.redeem === null || data.redeem['how_to_help'] === null || data.redeem['how_to_help'] ===''){
    dispatch(setOrderInfo(data))
    notification['success']({
      message: 'Notification Title',
      description:
        'Thank you for your purchasing',
    });
    return true
  }else {
    notification['error']({
      message: 'Sorry!',
      description:
        'I am sorry that, you have redeemed this order. Please change the order ID and have another try.',
    });
  }
  return false
}

export function push(titleKey){
  return {
    type: types.PUSH_TO,
    target: titleKey
  }
}

export function back(){
  return {
    type: types.BACK_GO,
  }
}

export function setStar(star){
  return{
    type: types.SET_STAR,
    star:star
  }
}

export function setPeriod(period){
  return{
    type: types.SET_PERIOD,
    period:period
  }
}

export function goFeedback2(star,period){
  return (dispatch,getState) => {
    dispatch(setStar(star))
    dispatch(setPeriod(period))
    dispatch(push('feedback2'))
  }
}

export function setImagePath(path){
  return{
    type: types.SET_IMAGE_LIST,
    path:path
  }
}

export function setNewsletter(newsletter){
  return{
    type: types.TOGGLE_NEWSLETTER,
    newsletter:newsletter
  }
}



export function deleteImagePath(path){
  return{
    type: types.DELETE_IMAGE,
    path:path
  }
}

export function setAddressInfo(address,city,state,zipcode){
  return{
    type: types.SET_ADDRESS_INFO,
    address:address,
    city:city,
    state:state,
    zipcode:zipcode
  }
}

export function setUserInfo(userName,email){
  return{
    type: types.SET_USER_INFO,
    userName:userName,
    email:email
  }
}

export function setFeedbackText(text){
  return{
    type: types.SET_FEEDBACK_TEXT,
    text:text
  }
}

export function setBenefit(benefit){
  return{
    type: types.SET_BENEFIT,
    benefit:benefit
  }
}


function check_url_tail(country){
  if ( country == 'CA') {
    return 'ca'
  }else if( country == 'UK'){
    return 'co.uk'
  }else{
    return 'com'
  }
}

function get_feedback_url(getState){


  var mystate = getState()
  var d = new Date();
  var today = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
  var country = mystate.order_info['items'][0]['country']
  var asin = mystate.order_info['items'][0]['ASIN']
  var order_id = mystate.order_info['AmazonOrderId']
  var period = mystate.order_info['period']
  var star = mystate.order_info['star']
  var seller_id = mystate.order_info['seller_id']
  var reward = mystate.order_info['reward'] ? mystate.order_info['reward'] : 10
  var benefit = mystate.order_info['benefit']
  var newsletter =  mystate.user_info['newsletter']
  var path = mystate.order_info['imagelist'].map((img)=>{return img.response.fileName})
  var userName = mystate.user_info['userName']
  var email = mystate.user_info['email']
  var address = mystate.user_info['address'] + ',' + mystate.user_info['city'] + ',' + mystate.user_info['state'] + ',' + mystate.user_info['zipcode']

  var request_url = `${remoteUrl}/graphql?query=mutation%20addRedeem%20{addRedeem(requestDate:"${today}",seller_id:"${seller_id}",AmazonOrderId:"${order_id}",asin:"${asin}",country:"${country}",source:"${config.get('source')}",amount:"${reward}",usingTime:"${period}",star:${star},how_to_help:"${benefit}",name:"${userName}",new_address:"${address}",email:"${email}",newsletter:${newsletter},attachments:"${path.join('||')}")%20{id}}`

  return request_url

}

export function send_feedback(){
  return (dispatch,getState) => {
      var request_url = get_feedback_url(getState)
      // dispatch(push('SuccessBanner'))
      axios.get(request_url).then(response => {
        dispatch(push('SuccessBanner'))
        notification['success']({
          message: 'Got It!',
          description:
            'Thank you for your review and information, we will contact you as soon as possible',
        });
      })
    }
}


export function send_email(){
  return (dispatch,getState) => {
      var request_url = get_email_url(getState)
    axios.get(request_url).then(response => {
      console.log('get email')
    })
    }
}


export function get_email_url(getState){
  var mystate = getState()
  var d = new Date();
  var today = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
  var country = mystate.order_info['items'][0]['country']
  var asin = mystate.order_info['items'][0]['ASIN']
  var order_id = mystate.order_info['AmazonOrderId']
  var star = mystate.order_info['star']
  var seller_id = mystate.order_info['seller_id']
  var newsletter =  mystate.user_info['newsletter']
  var email = mystate.user_info['email']

  var request_url = `${remoteUrl}/graphql?query=mutation%20addRedeem%20{addRedeem(requestDate:"${today}",seller_id:"${seller_id}",AmazonOrderId:"${order_id}",asin:"${asin}",country:"${country}",source:"${config.get('source')}",email:"${email}")%20{id}}`
  return request_url
}

export function send_email_benefit(){
  return (dispatch,getState) => {
      // var request_url = get_email_benefit_url(getState)
      var request_url = `${remoteUrl}api/v1/claim-review-feedbacks`;

      var mystate = getState();
      var d = new Date();
      var year = d.getFullYear();
      var monthNum = d.getMonth() + 1;
      var month = monthNum.toString().padStart(2, '0');
      var day = d.getDate();
      var today = year + '-' + month + '-' + day;

      var payload = {
        claim_review_feedback: {
          source: config.get('source'),
          email: mystate.user_info['email'],
          amount:mystate.order_info['reward'],
          date: today,
          type: mystate.order_info['benefit']
        }
      };
      var benefit = mystate.order_info['benefit']
      if (benefit == 'Amazon Gift Card' || benefit == 'Same Free Product') {
        payload.claim_review_feedback.type = benefit
      } else {
        payload.claim_review_feedback.type = 'Feedback'
        payload.claim_review_feedback.feedback = benefit
      }
      if (mystate.order_info['AmazonOrderId']) {
        payload.claim_review_feedback.amazon_order_id = mystate.order_info['AmazonOrderId']
      }
      
      axios({
        method: 'post',
        url: request_url,
        data: JSON.stringify(payload),
        headers: {'Content-Type': 'application/json'}
      }).then(response => {
        notification['success']({
          message: 'Got It!',
          description:
            'Thank you for your information！',
        });
      })
    }
}


