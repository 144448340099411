import React, {Component} from 'react';
import { Input, Form,Radio, Icon, Row, Button, Col,message,notification,Checkbox } from 'antd';
import Responsive from 'react-responsive-decorator';
import cat from '../backcat.png';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import config from 'react-global-configuration'
const TEMP_PRODUCT_LIST = [
  {
    "productName":"Same Free Proudct",
    "image":"https://m.media-amazon.com/images/I/51MAO2BuMiL._AC_SR480,480_.jpg"
  },
  {
    "productName":"Black Mask",
    "image":"https://m.media-amazon.com/images/I/51li7lef23L._AC_SR480,480_.jpg"
  },
  {
    "productName":"Black Mask2",
    "image":"https://m.media-amazon.com/images/I/41LTg5lNf-L._AC_SR480,480_.jpg"
  }
]
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class GetYourBenefit extends Component {

  constructor(props){
    super(props);
    this.state={
      benefit:"",

      userName:this.props.user_info['userName'],
      email:this.props.user_info['email'],
      address1:"",
      address2:"",
      cityName:"",
      stateName:"",
      zipcode:"",
      newsletter:true
    }
  }

  componentDidMount() {

  }

  check_url_tail(country){
    if ( country == 'CA') {
      return 'ca'
    }else if( country == 'UK'){
      return 'co.uk'
    }else{
      return 'com'
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleInformationSubmit(){
    var seller_id = this.props.order_info['seller_id']
    var asin = this.props.order_info['items'][0]['ASIN']
    var country = this.props.order_info['items'][0]['country']
    var url_country = this.check_url_tail(country)
    var order_id = this.props.order_info['AmazonOrderId']
    if (this.state.benefit !== ""){
      if (this.validateAddress()) {

        this.props.setAddressInfo(this.state.address1,this.state.cityName,this.state.stateName,this.state.zipcode)
        this.props.setBenefit(this.state.benefit)

        this.props.send_feedback()
      } else {
        notification['error']({
          message: 'Check your Address',
          description:
            <div>
              <p>Please complete shipping address.</p>
            </div>
        });
      }
    } else {
      notification['error']({
        message: 'Notification Title',
        description:
          <div><p>Please check if you have fulfill the user name, email, and benefit method</p>
          </div>
      });
    }
  }

  check_dollar_sign(country){
      if ( country == 'CA') {
        return 'C$'
      }else if( country == 'UK'){
        return '£'
      }else{
        return '$'
      }
    }



  getRewardPrice(){
    var order_reward = this.props.order_info['reward']
    if (order_reward == null || order_reward == 0){
      return
    }else{
      var country = this.props.order_info['items'][0]['country']
        var country_suffix = this.check_dollar_sign(country)

      return  (<Radio className='productUnit' value={"Amazon Gift Card"}>
             {country_suffix}{order_reward}  Amazon Gift Card
            </Radio>)
    }
  }

  handleUserNameChange = e =>{
    this.setState({
      userName: e.target.value,
    })
  }

  handleEmailChange = e =>{
    this.setState({
      email: e.target.value,
    })
  }

  handleBenefitMethod= e =>{
    this.setState({
      benefit: e.target.value,
    })
  }

  goback(){
    this.props.back()
  }

  getProductList(){
    var radioList = []
    let productList = this.props.order_info["freeProcuts"]
    if (productList.length > 0) {
      for (let i = 0; i < productList.length; i++) {
        // <br/>
        var request_url = `https://www.amazon.com/dp/${productList[i]["asin"]}?ref=myi_title_dp`
          radioList.push(<Radio className='productUnit' value={productList[i]["asin"]} key={i}>{productList[i]["asin"]}<a target="_blank" className='productUnitImage' href={request_url}><img className="productImage" src={productList[i]["image"]}/></a><a target="_blank"  className='productUnitDetalLink' href={request_url}>View Detail >></a></Radio>);
      }
      return radioList
    }else{
      return  (<Radio className='productUnit' value={"Same Free Product"}>
            Same Free Product
            </Radio>)
    }


  }

   handleAddress1Change = e =>{
    this.setState({
      address1: e.target.value,
    })
  }


   handleAddress2Change = e =>{
    this.setState({
      address2: e.target.value,
    })
  }

  handleCityChange = e =>{
    this.setState({
      cityName: e.target.value,
    })
  }

   handleStateChange = e =>{
    this.setState({
      stateName: e.target.value,
    })
  }

 handleZipcodeChange = e =>{
    this.setState({
      zipcode: e.target.value,
    })
  }

  validateAddress(){
    if (this.state.benefit != "Same Free Product"){
      return true
    }
    
    if (this.state.address1 == "" || this.state.cityName == "" || this.state.stateName == "" || this.state.zipcode == ""){
      return false
    }else{
      return true
    }
  }

   handleNewsletterChange = e =>{
    this.setState({
      newsletter: !e.target.value,
    })
  }



  handelHasAddressChange(){
    if (!this.state.benefit.includes("Gift Card")) {
      return (<div className="feedwrapper" >
       <p className="questitle"><b>Please finish the address information form, we will send you the bounus quickly!</b></p>



      <div className="clear"></div>
       <Row className="addressInput" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
        <Input
          onChange = {
            value => this.handleAddress1Change(value)
          }
          className="addressInput"
          size='large'
          value = {this.state.address1}
          placeholder="Street address"
          name="street-address" autoComplete="street-address" required
         
        />
     

      </Col>
       <div className="clear"></div>
        <Col span={12}>
       <Input
          onChange = {
            value => this.handleCityChange(value)
          }
          className="addressInput col-8"
          size='large'
          value = {this.state.cityName}
          placeholder="City"
        
        /></Col>
        <Col span={12}>
         <Input
          onChange = {
            value => this.handleStateChange(value)
          }
          className="addressInput col-8"
          size='large'
          value = {this.state.stateName}
          placeholder="State"
        
        /></Col>

<Col span={12}>
      <Input
          onChange = {
            value => this.handleZipcodeChange(value)
          }
          className="addressInput col-8"
          size='large'
          value = {this.state.zipcode}
          placeholder="Zipcode"
        
        /></Col>

           </Row>
      <div className="clear"></div>

      <span className="tips">Tips: Please provide your detailed address. If you would like to send the free product to a friend, you can also directly leave your friend's address.</span>
</div>)
    }else{
      return (<div></div>)
    }
  }


  render() {

    return (

      <div className="feedbox">
      <div className="feedwrapper">
      <p className="questitle"><b>Please select your benefit.</b></p>

           <br/>
          <Radio.Group onChange={this.handleBenefitMethod} value={this.state.benefit}>
           

            {this.getProductList()}
            {this.getRewardPrice()}
       
          </Radio.Group>
      </div>
      {this.handelHasAddressChange()}
      <div className="feedwrapper">
      
      <br/>
       
            <br/>
            <Button type="default" className="mydefaultbtn" onClick={this.goback.bind(this)}>Go Back </Button>
        <Button type="primary" className="mybtn" onClick={this.handleInformationSubmit.bind(this)}>Submit</Button>
         <div className="clear"></div>
         <div>
 <Checkbox style={{marginTop:"20px"}}  onChange={ this.handleNewsletterChange } defaultChecked={this.state.newsletter} >Join our Customers Club to Receive Newly Released Products, <br/>Amazon Gift Card & Lifetime Warranty.
</Checkbox>
 <br/>
 <br/>
 <p className="bottomtext bottomtextb"><strong>{config.get('source')}</strong> is the sole owner of the information collected from its customers. We will not sell or share this information with third parties in ways different from what is disclosed in our Privacy Policy.</p>
</div>
    </div>


       <div className="backimg"><img src={cat}/></div>
      </div>
    )
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info,
    user_info:state.user_info

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(GetYourBenefit);
