import React, { Component } from 'react';
import UploadImage from './UploadImage';
import './component.css'
import {
  Button,
  Row,
  message, Divider,
  notification,
  Col,Carousel
} from 'antd';
import cat from '../backcat.png';

import review1 from '../slider/1.jpg';
import review2 from '../slider/2.jpg';
import review3 from '../slider/3.jpg';
import review4 from '../slider/4.jpg';
import review5 from '../slider/5.jpg';


import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import { AmazonOutlined } from '@ant-design/icons';
const reviewCardStyle = {
  color : '#333',
  fontSize : 14,
  // paddingLeft: '30%',
  // paddingRight: '30%',
  margin: 'auto',
  width: '70%',
  overflowX : 'auto',
  overflowY : 'auto',
}


function check_dollar_sign(country){
      if ( country == 'CA') {
        return 'C$'
      }else if( country == 'UK'){
        return '£'
      }else{
        return '$'
      }
    }
function check_url_tail(country){
  if ( country == 'CA') {
    return 'ca'
  }else if( country == 'UK'){
    return 'co.uk'
  }else{
    return 'com'
  }
}

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};


class PositiveReview extends Component {

  getRewardPriceSentence(){
    var order_reward = this.props.order_info['reward']

 

     var country = this.props.order_info['items'][0]['country']
        var country_suffix = check_dollar_sign(country)

    if (order_reward == null){
      return  (<div className = "rewardText">
<p className="questitle">Thank you! We are so excited you came for your Benefit! 

</p>  <br/>
       
         <p className="reviewCardStyle">You have the option to receive a <strong>FREE PRODUCT</strong> for yourself or for someone you love when you complete these steps.
 </p>

        
         <p className="reviewCardStyle">for yourself or for someone you love when you complete these steps. We truly appreciate your review on Amazon as it helps us immensely! Please kindly support our growing business. </p>
         </div>)
    }else{
      return  ( <div className = "rewardText">
        <p className="questitle">Thank you! We are so excited you came for your Benefit! </p>
<br/>
{ order_reward <= 0 ?  <p className="reviewCardStyle">You can choose to receive a <strong>FREE PRODUCT</strong></p> : <p className="reviewCardStyle">You can choose to receive a <strong>{country_suffix}{order_reward} GIFT CARD</strong> OR a <strong>FREE PRODUCT</strong></p>}
     
        <p className="reviewCardStyle">when you complete these steps. We truly appreciate your review on Amazon as it helps us immensely! Please kindly support our growing business. </p>
       </div>)
    }
  }

  goback(){
    this.props.back()
  }



  submitReview(){
    if (this.props.order_info['imagelist'].length > 0) {

      message.success('Thank you for your feedback.')
      this.props.push('benefit')
    } else {
      notification['error']({
        message: 'Notification Title',
        description:
          <div><p>Please upload the screenshot of your review</p>
          </div>
      });
    }
  }

  gotoAmazonLink(){
    var country = 'com'
    if (this.props.order_info['AmazonOrderId'] != ''){

        var country = this.props.order_info['items'][0]['country']
        var country_suffix = check_url_tail(country)
        var asin = this.props.order_info['items'][0]['ASIN']
        window.open(
            `https://www.amazon.${country_suffix}/review/create-review/?asin=${asin}%3A5`,
            '_blank' // <- This is what makes it open in a new window.
          );
    }else{
      this.checkOrderId()
    }
  }



  render() {
    var country = this.props.order_info['items'][0]['country']
    var asin = this.props.order_info['items'][0]['ASIN']
    var order_id = this.props.order_info['AmazonOrderId']
    var url_country = check_url_tail(country)
    return (
      <div id='reviewb' className="feedbox">
      <div className="contentbox">
        {this.getRewardPriceSentence()}
         
         <p className="reviewCardStyle"><b>Please save your review screenshot and return it here to upload it, so that you can unlock your benefit! Thank you for your business and your time!</b></p>

         <Button type="primary" style={{marginTop:"20px",marginBottom:"20px"}}  className="mybtn mybtna"  target="_blank" onClick={()=>this.gotoAmazonLink() }><AmazonOutlined /> <p>Leave Feedback</p>
</Button>
 <div className="clear"></div>

      <span className="tips" style={{fontSize:"13px",marginTop:'0px',color:'#0fb6fc', maxWidth:'400px'}}>Leave Feedback for the products on Amazon and claim all offers</span>
  </div>
  <div className="contentbox">

 <Divider />
<p className="questitle">Upload Review Screenshot</p>
<br/>
        <UploadImage className="uploadimage" upload_allowed={true}  OrderId={order_id} ASIN={asin} handleReviewScreenShotSubmit={this.handleReviewScreenShotSubmit} OrderId={order_id}/>
    <p className = "uploadImageTips" style={{fontSize:"14px",marginTop:'-10px'}}>maxiumn file size:1 MB</p>
            <Button type="default" className="mydefaultbtn" onClick={this.goback.bind(this)}>Go Back </Button>
            <Button type="primary" className="mybtn" onClick={this.submitReview.bind(this)}>Next</Button>
            

         </div>
         <div className="clear"></div>
         <div className="sliderimg">

          <Carousel autoplay autoplaySpeed={2000}>
    <div>
      <img src={review1}/>
    </div>
    <div>
      <img src={review2}/>
    </div>
    <div>
      <img src={review3}/>
    </div>
    <div>
      <img src={review4}/>
    </div>
    <div>
      <img src={review5}/>
    </div>
  </Carousel>


  </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(PositiveReview);
