import React, { Suspense, useEffect } from "react";
import { applyMiddleware, createStore, compose } from 'redux'
import GetReview from './getReview/GetReview';
import Background from './background.jpg';
import configureStore from './store/configureStore'
import { Provider } from 'react-redux'
import { GlobalDebug } from "./remove-consoles";

const store = configureStore()

function App() {

   /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  // console.log("I am just another dummy console log, suppose to be suppressed 🙂");


  return (
  	 <Provider store={store}>
        <div className="overwapper">
        </div>
          <div className="background">
          <GetReview/>
          </div>
          
    </Provider>

  );
}

export default App;
